import CookieService from "../../../../../common/ts/services/CookieService";
import SplitTests from "../../../../../common/ts/SplitTests";
import FeatureFlags, {TROVIT_FIRST_INTERACTION_MANAGEMENT} from "../../../../../common/ts/FeatureFlags";
import {EventBus} from "../../../../../common/ts/events/EventBus";
import {FirstInteractionEvent, GoogleOneTapFlowEvent} from "../../../../../common/ts/events/Events";

const FIRST_INTERACTION_COOKIE_NAME = "trovitUserFirstInteraction"

export class FirstInteractionController {
    private readonly eventBus: EventBus;
    private readonly featureFlag: FeatureFlags;
    private readonly cookieService: CookieService;
    private readonly splitTest: SplitTests

    constructor(eventBus: EventBus) {
        this.eventBus = eventBus
        this.featureFlag = new FeatureFlags();
        this.cookieService = new CookieService();
        this.splitTest = new SplitTests()
    }

    isFirstInteraction() {
        return this.featureFlag.isActiveFeature(TROVIT_FIRST_INTERACTION_MANAGEMENT) &&
            !this.cookieService.getCookie(FIRST_INTERACTION_COOKIE_NAME)
            // &&
            // this.splitTest.isActive(2025012102, 1)
    }

    actionForFirstInteraction() {
        this.cookieService.setSessionCookie(FIRST_INTERACTION_COOKIE_NAME, 'true');
        this.eventBus.emit(new FirstInteractionEvent({flow: GoogleOneTapFlowEvent.NOT_USED}))
    }
}