export const GOOGLE_ONE_TAP_DEBUG_FEATURE_FLAG: string = "printConsoleDebug";
export const LAMUDI_MULTI_LOCATION_MODAL_FILTER_FEATURE_FLAG: string = "3843";
export const HIPFLAT_WEB_COMPONENT_FEATURE_FLAG: string = "webcomponent";
export const TROVIT_USER_SEARCH_FEATURE_FLAG: string = "PROD-10694";
export const TROVIT_TRACK_GA4_FILTERS: string = "PROD-11241";
export const TROVIT_FIRST_INTERACTION_MANAGEMENT: string = "PROD-11645"

export default class FeatureFlags {
    isActiveFeature = (featureName: string): boolean => {
        const urlParams = new URLSearchParams(window.location.search);
        const feature = Boolean(urlParams.get(featureName))
        if (feature) {
            return true;
        }
        const cookie = document.cookie;
        return cookie.includes(featureName);
    }
};
